import { Box, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import React, { FC } from 'react';

interface ExpiredOtpPageProps {}

const primary = purple[500]; // #f44336

const ExpiredOtpPage: FC<ExpiredOtpPageProps> = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
      backgroundColor: primary,
    }}
  >
    <Typography variant="h1" style={{ color: 'white' }}>
      OTP expired
    </Typography>
  </Box>
);

export default ExpiredOtpPage;
