import type { RouteObject } from 'react-router-dom';
import OtpFormPage from '../components/OtpFormPage/OtpFormPage';
import Layout from '../components/Layout/Layout';
import OtpErrorPage from '../components/OtpErrorPage/OtpErrorPage';
import ExpiredOtpPage from '../components/ExpiredOtpPage/ExpiredOtpPage';
import UploadPage from '../components/UploadPage/UploadPage';

const routes: RouteObject[] = [
  {
    element: <Layout />,
    path: 'w',
    children: [
      {
        path: 'otp',
        children: [
          {
            path: 'check',
            children: [
              {
                path: ':verificationCode',
                element: <OtpFormPage />,
                children: [
                  {
                    path: ':verifyCode',
                    element: <OtpFormPage />,
                  },
                ],
              },
            ],
          },
          {
            path: 'expired',
            element: <ExpiredOtpPage />,
          },

          {
            path: 'error',
            element: <OtpErrorPage />,
          },
        ],
      },
      {
        path: 'download-area',
        children: [
          {
            path: ':verificationCode',
            element: <UploadPage />,
          },
        ],
      },
    ],
  },
];

export default routes;
