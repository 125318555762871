import {
  Container,
  Box,
  Avatar,
  Typography,
  FormHelperText,
  Button,
  Paper,
  Grid,
  Stack,
  Alert,
  Link,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { FC, useState } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { checkOtpFn, meFn, requestForNewOTPFn } from "../../api/api";
import SendIcon from "@mui/icons-material/Send";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

interface OtpFormPageProps {}

type CheckOtpInputType = {
  otp: string;
};

const ReSendButton = (props: any) => {
  const { verificationCode = "" } = useParams();
  const [otpNotSent, setOtpNotSent] = useState<boolean>(false);
  const [otpSendError, setOtpSendError] = useState<boolean>(false);

  const { mutate: requestForNewOtp } = useMutation(
    (verificationCode: string) => requestForNewOTPFn(verificationCode),
    {
      onSuccess(data) {
        if (data.status === "sent") {
          setOtpNotSent(true);
        }
      },
      onError() {
        setOtpSendError(true);
      },
    }
  );
  const otpResendHandler = () => {
    setOtpNotSent(false);
    setOtpSendError(false);
    requestForNewOtp(verificationCode);
  };
  return (
    <Stack>
      <Button
        sx={{
          textTransform: "unset !important",
          font: "Arial",
          fontWeight: "700",
          color: "#024E7D",
          fontSize: "16px",
          lineHeight: "16px",
        }}
        onClick={otpResendHandler}
        {...props}
      />
      {otpNotSent && (
        <Typography variant="body2">
          Veuillez ré-essayer dans une minute
        </Typography>
      )}
      {otpSendError && (
        <Typography variant="body2">
          Une erreur est survenu pendant la demande de renvoi de code OTP
        </Typography>
      )}
    </Stack>
  );
};

const OtpFormPage: FC<OtpFormPageProps> = () => {
  const { verificationCode = "" } = useParams();
  const [otpError, setOtpError] = useState<boolean>(false);
  const [unknownError, setUnknownError] = useState<boolean>(false);

  const { data } = useQuery(["linkCode", { verificationCode }], meFn);

  const { mutate: checkOtp } = useMutation(
    (otpData: CheckOtpInputType) => checkOtpFn(verificationCode, otpData.otp),
    {
      onError() {
        setUnknownError(true);
      },
      onSuccess(data) {
        if ("status" in data) {
          switch (data.status) {
            case "checked":
              if (data.datas && "redirectTo" in data.datas) {
                // The user won't be able to use the back button to navigate to it.
                window.location.replace(data.datas["redirectTo"]);
              }
              break;
            case "unchecked":
              setOtpError(true);
              break;
            default:
              setUnknownError(true);
              break;
          }
        } else {
          setUnknownError(true);
        }
      },
    }
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      otp: "",
    },
  });

  const onSubmitHandler: SubmitHandler<CheckOtpInputType> = (
    value: CheckOtpInputType
  ) => {
    setUnknownError(false);
    setOtpError(false);
    checkOtp(value);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          marginTop: 8,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4}>
            {data && data.datas && data.datas!.logo ? (
              <Box
                component="img"
                sx={{
                  m: 3,
                  height: 48,
                }}
                alt="Logo"
                src={data && data.datas!.logo}
              />
            ) : (
              <>
                <Avatar sx={{ m: 3, bgcolor: "primary.main" }}>
                  <VerifiedUserIcon />
                </Avatar>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            {data && data.datas && data.datas!.title && (
              <Typography
                sx={{
                  fontFamily: "Arial",
                  fontFize: "16px",
                  fontWeight: "400",
                  lineHeight: "16px",
                  letterSpacing: "0em",
                  textAlign: "right",
                  whiteSpace: "pre",
                  margin: "24px",
                  color: "#024E7D",
                }}
              >
                {data && data.datas!.title}
              </Typography>
            )}
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontFamily: "Arial",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#024E7D",
                mb: "15px",
              }}
            >
              Vérification SMS
            </Typography>
            <Typography
              sx={{
                fontFamily: "Arial",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "16px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              Pour accéder au contenu sécurisé,
            </Typography>
            <Typography
              sx={{
                fontFamily: "Arial",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "16px",
                letterSpacing: "0em",
                textAlign: "center",
              }}
            >
              saisissez le code reçu par SMS
            </Typography>

            <Controller
              name="otp"
              control={control}
              rules={{ validate: (value) => value.length === 4 }}
              render={({ field, fieldState }) => (
                <Box sx={{ mt: 2 }}>
                  <MuiOtpInput
                    sx={{
                      width: "280px",
                      gap: "10px",
                      mb: "15px",
                      mt: "15px",
                    }}
                    {...field}
                    length={4}
                  />
                  {fieldState.invalid ? (
                    <FormHelperText error>OTP invalid</FormHelperText>
                  ) : null}
                </Box>
              )}
            />

            <Stack direction="row" justifyContent="center" alignItems="center">
              <Typography
                sx={{
                  fontfamily: "Arial",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "16px",
                  letterSpacing: "0em",
                  textAlign: "center",
                }}
              >
                SMS non reçu ?
              </Typography>
              <ReSendButton>Réessayer</ReSendButton>
            </Stack>

            <Typography
              sx={{
                fontFamily: "Arial",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#024E7D",
                whiteSpace: "pre",
                lineHeight: "16px",
                mt: 1,
                mb: 1,
              }}
              variant="body2"
            >
              {`Si vous ne recevez toujours pas de SMS,
rapprochez vous de votre conseiller Allianz`}
            </Typography>

            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "133px",
                height: "39px",
                padding: "10px",
                borderRadius: "5px",
                gap: "10px",
                bgcolor: "#024E7D",
                textTransform: "unset !important",
                mt: "15px",
                mb: "50px",
              }}
            >
              Valider
            </Button>

            {otpError && (
              <Box>
                <Alert severity="warning" sx={{ mt: 2 }}>
                  <Stack>
                    <Typography variant="body1">
                      Le code OTP est erronné ou a expiré. Essayez de le saisir
                      à nouveau ou demandez en un nouveau.
                    </Typography>
                    <ReSendButton
                      sx={{
                        mt: 4,
                      }}
                      variant="outlined"
                      endIcon={<SendIcon />}
                    >
                      Nouveau code OTP
                    </ReSendButton>
                    <Typography variant="subtitle2" marginTop="20px">
                      Attention : il y aura un délai de 5 minutes toutes les 3
                      tentatives. Si le problème persiste, veuillez contacter le{" "}
                      {data && data.datas && data.datas!.support ? (
                        <Link href={`mailto:${data && data.datas!.support}`}>
                          support.
                        </Link>
                      ) : (
                        <>support.</>
                      )}
                    </Typography>
                  </Stack>
                </Alert>
              </Box>
            )}
            {unknownError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                <Stack>
                  <Typography variant="body1">
                    Une erreur inconnue s'est produite.
                  </Typography>
                  <Typography variant="subtitle2" marginTop="20px">
                    Si le problème persiste, veuillez contacter le{" "}
                    {data && data.datas && data.datas!.support ? (
                      <Link href={`mailto:${data && data.datas!.support}`}>
                        support.
                      </Link>
                    ) : (
                      <>support.</>
                    )}
                  </Typography>
                </Stack>
              </Alert>
            )}
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default OtpFormPage;
