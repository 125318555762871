import { Box, Container, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import React, { FC } from 'react';

const primary = blue[500];

interface OtpErrorPageProps {}

const OtpErrorPage: FC<OtpErrorPageProps> = () => (
  <Container component="main" maxWidth="sm">
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'white' }}>
        La page que vous recherchez n'existe pas.
      </Typography>
    </Box>{' '}
  </Container>
);

export default OtpErrorPage;
