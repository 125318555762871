import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FC } from "react";
import DownloadIcon from "@mui/icons-material/Download";

interface UploadItemProps {
  code: string;
  url: string;
  title: string;
  contentType: string;
  fileName: string;
  id: string;
}

const UploadItem: FC<UploadItemProps> = (props: UploadItemProps) => {
  const { title, fileName, id, code } = props;

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
            >
              <Typography
                variant="h6"
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {title}
              </Typography>
              <Typography color={grey[500]} variant="subtitle2">
                {fileName}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              aria-label="Upload file"
              href={`${process.env.REACT_APP_U_SHORT_API}/${code}/download/${id}`}
            >
              <Avatar sx={{ bgcolor: "#024E7D" }}>
                <DownloadIcon />
              </Avatar>
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UploadItem;
