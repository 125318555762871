import {
  Box,
  Toolbar,
  Typography,
  Stack,
  Paper,
  Container,
  Grid,
  Avatar,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

import UploadItem from "../UploadItem/UploadItem";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { meFn } from "../../api/api";
import DownloadIcon from "@mui/icons-material/Download";

interface UploadPageProps {}

const UploadPage: FC<UploadPageProps> = () => {
  const { verificationCode = "" } = useParams();
  const [urls, setUrls] = useState([]);
  const { data } = useQuery(["linkCode", { verificationCode }], meFn);

  useEffect(() => {
    if (data && data.datas && Array.isArray(data.datas.urls)) {
      setUrls([...urls, ...(data.datas.urls as [])]);
    }
  }, [data]);

  return (
    <Container component="main" maxWidth="md">
      <Paper
        elevation={3}
        sx={{
          marginTop: 8,
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          sx={{ mt: "10px" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              {data && data.datas && data.datas!.logo ? (
                <Box
                  component="img"
                  sx={{
                    m: 3,
                    height: 48,
                  }}
                  alt="Logo"
                  src={data && data.datas!.logo}
                />
              ) : (
                <>
                  <Avatar sx={{ m: 3, bgcolor: "primary.main" }}>
                    <DownloadIcon />
                  </Avatar>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              {data && data.datas && data.datas!.title && (
                <Typography
                  sx={{
                    fontFamily: "Arial",
                    fontFize: "16px",
                    fontWeight: "400",
                    lineHeight: "16px",
                    letterSpacing: "0em",
                    textAlign: "right",
                    whiteSpace: "pre",
                    margin: "24px",
                    color: "#024E7D",
                  }}
                >
                  {data && data.datas!.title}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                fontFamily: "Arial",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#024E7D",
                mb: "30px",
              }}
            >
              Aire de téléchargement
            </Typography>
          </Stack>
          {/* <Toolbar>
              <Stack sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  Fichiers dans ce transfert
                </Typography>
              </Stack>
            </Toolbar> */}

          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {urls.map((value: any, index) => (
                <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                  <UploadItem
                    title={value.title}
                    url={value.url}
                    fileName={value.fileName}
                    contentType={value.contentType}
                    id={value._id}
                    code={verificationCode}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Paper>
    </Container>
  );
};
export default UploadPage;
