import axios from 'axios';
import { GenericResponse } from './types';

export const uShortApi = axios.create({
  baseURL: process.env.REACT_APP_U_SHORT_API,
});

export const meFn = async (inputs: any) => {
  const [_key, { verificationCode }] = inputs.queryKey;
  const resp = await uShortApi.get<GenericResponse>(`/${verificationCode}/me`);
  return resp.data;
};

export const requestForNewOTPFn = async (verificationCode: string) => {
  const resp = await uShortApi.post<GenericResponse>(
    `/${verificationCode}/resend-otp`,
  );
  return resp.data;
};

export const checkOtpFn = async (verificationCode: string, otpCode: string) => {
  const resp = await uShortApi.post<GenericResponse>(
    `/${verificationCode}/checkotp`,
    { otp: otpCode },
  );
  return resp.data;
};
